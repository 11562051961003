<template>
	<div class="container">

		<Header></Header>

		<!-- <div class="breadcrumbs wrap">
			<img src="../../assets/images/icon-home.png" alt=""  class="breadcrumbs-icon"/>
			当前位置: 首页 > 品牌详情
		</div> -->
		<!-- <el-breadcrumb separator-class="el-icon-arrow-right">
		  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
		  <el-breadcrumb-item>活动管理</el-breadcrumb-item>
		  <el-breadcrumb-item>活动列表</el-breadcrumb-item>
		  <el-breadcrumb-item>活动详情</el-breadcrumb-item>
		</el-breadcrumb> -->
		<div class="detail2-top-wrap">
			<div class="detail2-top wrap clearfix">
				
				<div class="detail2-top-left fl">
					<div class="detail2-top-left-dw"></div>
					<div class="detail2-top-left-img">
						<img src="../../assets/images/detail-top-logo_03.png" alt="" />
					</div>
					<div class="detail2-top-left-v">
						<img src="../../assets/images/detail2-v.png" alt="" />
					</div>
					<a href="#" class="detail2-top-left-btn mt-25">进入官网<img src="../../assets/images/icon-arrow-bai.png" alt="" /></a>
					<div class="mt-25 detail2-top-left-low">
						<span class="detail-top1r-tag">
							<img src="../../assets/images/detail-top-bq1.png" alt="" />
							<img src="../../assets/images/detail-top-bq2.png" alt="" />
							<img src="../../assets/images/detail-top-bq3.png" alt="" />
							<img src="../../assets/images/detail-top-bq4.png" alt="" />
						</span>
					</div>
					
				</div><!-- detail2-top-left end -->
				<div class="detail2-top-right fr">
					<div class="detail-left-hd">
						<a href="#ppjs" @click="detailNavClick(0)" :class="detailNavIndex==0?'active':''">品牌介绍</a>
						<a href="#xgpp" @click="detailNavClick(1)" :class="detailNavIndex==1?'active':''">相关品牌</a>
						<a href="#zsjm" @click="detailNavClick(2)" :class="detailNavIndex==2?'active':''">招商加盟</a>
						<a href="#qyxx" @click="detailNavClick(3)" :class="detailNavIndex==3?'active':''">企业信息</a>
					</div><!-- detail-left-hd end -->
					
					<div class="detail2-top-rCon  mt-20 clearfix">
						
						<div class="detail2-top-info fl">
							
							<div class="detail-top1-c1 clearfix">
								<h4>立邦油漆</h4>
								<div class="detail-top1-c2 fl">
									<img src="../../assets/images/icon-phone-blue.png" alt="" />
									<font>400-352-5512</font>
								</div>
							</div>
							
							<div class="detail2-top-info-tb">
								<div class="detail-top2">
									<p><span>苏州安心实业有限公司</span> <img src="../../assets/images/icon-gongsi.png" class="icon-gongsi"/></p>
									<p><span>品牌创立时间: </span>1998年</p>
									<p><span>注册金额: </span>125125万</p>
									<p><span>品牌发源地: </span>香港</p>
									<p><span>总部地址: </span><img src="../../assets/images/icon-address-blue.png"/>北京市东城区东中街9号</p>
									<p><span>关注指数: </span><img src="../../assets/images/icon-zhishu.png"/>300 人</p>
									<p>
										<span>品牌网店: </span> 
										<a class="detail2-top-shop"><img src="../../assets/images/detail-top-shopLogo_06.png" alt="" />京东旗舰店</a>
										<a class="detail2-top-shop"><img src="../../assets/images/detail-top-shopLogo_08.png" alt="" />天猫旗舰店</a>
										<a class="detail2-top-shop"><img src="../../assets/images/detail-top-shopLogo_10.png" alt="" />京东自营店</a>
									</p>
								</div><!-- detail-top2 end -->
							</div>
						</div><!-- detail2-top-info end -->
						
						<div class="detail2-top-ry fr">
							<div class="detail2-top-ry-smaill">
								<h3 class="detail2-top-ry-h">
									<img src="../../assets/images/detail2-top-r-h3l.png"/>
									<span>品牌荣誉</span>
									<img src="../../assets/images/detail2-top-r-h3r.png"/>
								</h3>
								<div class="detail2-top-ry-txt">
									<p>2010年12月8日，由世界直销（中国）研究中心主办、《中国直销》杂志承办的'中国直销新格局·第六届（2010）中国直010年12杂志榜颁奖现场。</p>
									<p>司分别被评为年度“最具潜力企2010年12月8日，由世界直销（中国）研究中心主办、《中国直销》杂志承办的'中国直销新格局·第六届（2010）中国直010年12杂志榜颁奖现场</p>
								</div>
							</div>
							
							<div class="detail2-top-ry-big">
								<div class="detail2-top-ry-sroall">
									<div class="detail2-top-ry-txt">
										<p>2010年12月8日，由世界直销（中国）研究中心主办、《中国直销》杂志承办的'中国直销新格局·第六届（2010）中国直010年12杂志榜颁奖现场。</p>
										<p>司分别被评为年度“最具潜力企2010年12月8日，由世界直销（中国）研究中心主办、《中国直销》杂志承办的'中国直销新格局·第六届（2010）中国直010年12杂志榜颁奖现场2010年12月8日，由世界直销（中国）研究中心主办、《中国直销》杂志承办的'中国直销新格局·第六届（2010）中国直010年12杂志榜颁奖现场2010年12月8日，由</p>
									</div>
								</div>
							</div>
						</div><!-- detail2-top-ry end -->
					</div>
				</div><!-- detail2-top-right end -->
			</div>
		</div><!-- detail2-top-wrap end -->
		
		<div class="i-main wrap clearfix mt-25">
			<div class="detail-con clearfix ">
				<div class="detail-left fl border-10">
					<div class="detail-left-body">
						<div class="detail-l1" id="ppjs">
							<div class="detail-left-tit">
								<span>品牌介绍</span>
							</div>
							<div class="detail-l1-pic mt-25">
								<img src="../../assets/images/detail-l1-pic_03.png" alt="" />
							</div>
							
							<div class="detail-l1-text" :class="textShow1?'textShow1':''">
								<p>法国著名奢侈品品牌，始于1910年，经营时尚精品、腕表、高级珠宝和香水及美容品而闻名于世，双C标志、菱形格纹、山茶花为品牌标志，No.5香水、2.55手袋、小黑裙、双色鞋是时尚界永恒经典</p>
								<p>1910年，嘉柏丽尔·香奈儿在巴黎康朋街21号开设了自己的第一家女帽店，以“香奈儿时尚”为名，制作帽子。她的设计受到当时著名嘉柏丽尔·香奈儿在巴黎康朋街21号开设了自己的第一家女帽...</p>
								<p>1910年，嘉柏丽尔·香奈儿在巴黎康朋街21号开设了自己的第一家女帽店，以“香奈儿时尚”为名，制作帽子。她的设计受到当时著名嘉柏丽尔·香奈儿在巴黎康朋街21号开设了自己的第一家女帽...</p>
								<p>1910年，嘉柏丽尔·香奈儿在巴黎康朋街21号开设了自己的第一家女帽店，以“香奈儿时尚”为名，制作帽子。她的设计受到当时著名嘉柏丽尔·香奈儿在巴黎康朋街21号开设了自己的第一家女帽...</p>
							</div>
							
							<div class="detail-moreBtn mt-30" @click="textShowBtn1">
								<span v-if="!textShow1">更多</span>
								<span v-else>收起</span>
								<img src="../../assets/images/icon-add.png" alt="" />
							</div>	
							
						</div><!-- detail-l1 end -->
						
						<div class="detail-l2">
							
							<div class="detail-left-tit">
								<span>相关网店</span>
							</div>
							
							<div class="detail-l2-listbox mt-25">
								<ul class="detail-l2-list clearfix" :class="textShow2?'textShow2':''">
									<li v-for="(item,a) in 6" :key="a">
										<div class="detail-l2-list-img">
											<img src="../../assets/images/detail-l2-logo_03.png" alt="" />
										</div>
										<div class="detail-l2-list-info">
											<h4>chindler迅达电梯品牌</h4>
											<div class="detail-l2-list-low clearfix">
												<a href="#" class="detail-l2-list-link fr">进店></a>
												<span><img src="../../assets/images/icon-jd.png" alt="" />京东</span>
												<span><img src="../../assets/images/icon-zmd.png" alt="" />专卖店</span>
											</div>
										</div>
									</li>
								</ul>
							</div>
							<div class="detail-moreBtn" @click="textShowBtn2">
								<span v-if="!textShow2">更多</span>
								<span v-else>收起</span>
								<img src="../../assets/images/icon-add.png" alt="" />
							</div>	
						</div><!-- detail-l2 end -->
						
						<div class="detail-l3 mt-25" id="xgpp">
							
							<div class="detail-left-tit">
								<span>相关品牌</span>
							</div>
							
							<ul class="detail-l3-list" :class="textShow3?'textShow3':''">
								<li v-for="(item,b) in 5" :key="b">
									<div class="detail-l3-list-img">
										<img src="../../assets/images/detail-l3-img_03.png" alt="" />
									</div>
									<div class="detail-l3-list-info">
										<a href="#">
											<h4>中国平安</h4>
											<p>隶属台湾雅完美化妆品有限公司，经营范围以洗面奶、美白套装、祛斑套装以及补水套装等系列产品为主</p>
										</a>
									</div>
								</li>
							</ul>
							
							<div class="detail-moreBtn mt-25" @click="textShowBtn3">
								<span v-if="!textShow3">更多</span>
								<span v-else>收起</span>
								<img src="../../assets/images/icon-add.png" alt="" />
							</div>
							
						</div><!-- detail-l3 end -->
						
						
						<div class="detail-l4 mt-25" id="zsjm">
							
							<div class="detail-left-tit">
								<span>招商加盟</span>
							</div>
							
							<div class="detail-l4-con">
								
								<dl class="detail-l4-dl">
									<dt>品牌名称：</dt>
									<dd>美的/MIDEA</dd>
								</dl>
								<dl class="detail-l4-dl">
									<dt>经营模式：</dt>
									<dd>经销   代理  合作  直营</dd>
								</dl>
								<dl class="detail-l4-dl">
									<dt>发展模式：</dt>
									<dd>经销 区域代理</dd>
								</dl>
								<dl class="detail-l4-dl">
									<dt>适合人群：</dt>
									<dd>自由创业  在港投资 现有公司增项</dd>
								</dl>
								<dl class="detail-l4-dl">
									<dt>基本投资：</dt>
									<dd>20~50万</dd>
								</dl>
								<dl class="detail-l4-dl">
									<dt>招商电话：</dt>
									<dd>0757-26338888</dd>
								</dl>
								<dl class="detail-l4-dl">
									<dt>招商邮箱：</dt>
									<dd>美的/MIDEA</dd>
								</dl>
								<dl class="detail-l4-dl">
									<dt>招商部门：</dt>
									<dd>招商部</dd>
								</dl>
								
							</div>
							
							
						</div><!-- detail-l4 end -->
						
						<div class="detail-l5 mt-45" id="qyxx">
							
							<div class="detail-left-tit">
								<span>企业信息</span>
							</div>
							
							<div class="detail-l5-con clearfix">
								<div class="detail-l5-table" :class="tableIndex % 2 !==0?'odd':''">
									<dl class="detail-l5-dl">
										<dt><span>企业名称：</span></dt>
										<dd>辽宁普创科技有限公司</dd>
									</dl>
									<dl class="detail-l5-dl">
										<dt>企业名称：</dt>
										<dd>辽宁普创科技有限公司</dd>
									</dl>
									
									<dl class="detail-l5-dl">
										<dt>企业名称：</dt>
										<dd>辽宁普创科技有限公司</dd>
									</dl>
									<dl class="detail-l5-dl">
										<dt>企业名称：</dt>
										<dd>辽宁普创科技有限公司</dd>
									</dl>
									<dl class="detail-l5-dl">
										<dt>企业名称：</dt>
										<dd>辽宁普创科技有限公司</dd>
									</dl>
									<dl class="detail-l5-dl">
										<dt>企业名称：</dt>
										<dd>辽宁普创科技有限公司</dd>
									</dl>
									
									<dl class="detail-l5-dl">
										<dt>企业名称：</dt>
										<dd>辽宁普创科技有限公司</dd>
									</dl>
									<dl class="detail-l5-dl">
										<dt>企业名称：</dt>
										<dd>辽宁普创科技有限公司</dd>
									</dl>
									<dl class="detail-l5-dl">
										<dt>企业名称：</dt>
										<dd>辽宁普创科技有限公司</dd>
									</dl>
								</div>
								
								<dl class="detail-l5-dl w100">
									<dt><span>企业名称：</span></dt>
									<dd>一般经营项目是：从事高科技产品的研究、开发、销售、服务；从事对外投资业务；提供管理、咨询、培训等业务；IT服务业务；自有房屋租赁。<a href="#">更多></a></dd>
								</dl>
								
							</div>
							
							<div class="detail-l5-tx mt-35">
								<span class="el-icon el-icon-warning-outline"></span>
								提醒: 可能因企业资料变更未及时更新造成与实际登记有差别，请以国家部门核准登记的为准。
							</div>
							
						</div><!-- detail-l5 end -->
						
						<div class="article-l4-zanbox">
							<div  class="article-l4-zan" @click="zanBtn">
								<span></span>
								<p>{{zanNum}}</p>
							</div>
						</div>
					</div><!-- detail-left-body end -->
					
				
				</div><!-- detail-left end -->
				
				<div class="detail-right fr">
					<div class="detail-r-hy">
						<div class="detail-rTit">按行业查看</div>
						<div class="detail-r-hy-listBox">
							<ul class="detail-r-hy-list clearfix">
								<li v-for="(item,c) in 6" :key="c">
									<a href="#">
										<span><img src="../../assets/images/detail-r1-img.png" alt="" /></span>
										<p>厨房电器</p>
									</a>
								</li>
							</ul>
							<div class="detail-rMore mt-30">
								<a href="#">查看更多 <img src="../../assets/images/icon-arrow-more.png"/></a>
							</div>
						</div>
						
					</div>
					<div class="detail-r-pp">
						<div class="detail-rTit">按品牌查看</div>
						
						
						<div class="detail-r2-list">
							<ul>
								<li v-for="(item,index) in 10" :key="index">
									<a href="#">
										<img src="../../assets/images/rank-r2-img_03.png" alt="" />
									</a>
								</li>
							</ul>
						</div>
						
					</div>
				</div><!-- detail-right end -->
			</div><!-- detail-con end -->
			
		</div><!-- i-main end  -->
		
			
		<div class="i-m9-wrap mt-40">
			<div class="i-m9 wrap clearfix">
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_03.png" alt="" class="i-m9-icon"/>
					<div class="i-m9-info">
						<h4>全球品牌总数</h4>
						<p>300000<em>+</em></p>
					</div>
				</div>
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_06.png" alt="" class="i-m9-icon"/>
					<div class="i-m9-info">
						<h4>城市精选商家</h4>
						<p>100000<em>+</em></p>
					</div>
				</div>
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_09.png" alt="" class="i-m9-icon"/>
					<div class="i-m9-info">
						<h4>行业品牌榜单</h4>
						<p>5000<em>+</em></p>
					</div>
				</div>
			</div>
		</div><!-- i-m9-wrap end -->		
			
		<Footer></Footer>
		
		<el-backtop style="width:50px;height:50px;">
			<div style="{
          height: 100%;
          width: 100%;
          background: #da0505 ;
          box-shadow: 0 0 6px rgba(0,0,0, .12);
          text-align: center;
          z-index: 9999;
        }">
				<img src="../../assets/images/backtop.png" alt="" style="vertical-align: top;">
			</div>
		</el-backtop>
		
		
	</div>
</template>

<script>
	// import {banner} from "../../api/index.js";
	
	import Footer from "@/components/footer";
	import Header from "@/components/header";
	export default {
		name: "detail2",
		components: {
			Header,
			Footer
		},
		data() {
			return {
				detailNavIndex:0,//导航
				textShow1:false,//控制更多显示
				textShow2:false,//控制更多显示
				textShow3:false,//控制更多显示
				
				tableIndex:9,//表格数量
				
				
				imgUrl:'http://1.94.30.25:8088',
				
				leftList:[],
			
				zanNum:4855,
				
			};
		},
	
		created() {
			this.initPage();
		},

		computed: {},

		mounted() {},

		methods: {
			initPage(){
				this.getBanner()
			
			},
			
			//左侧导航
			detailNavClick(index){
				this.detailNavIndex = index
			},
			//点赞
			zanBtn(){
				this.zanNum = this.zanNum+1
			},

			//更多展开
			textShowBtn1(){
				this.textShow1 = !this.textShow1
			},

			textShowBtn2(){
				this.textShow2 = !this.textShow2
			},

			textShowBtn3(){
				this.textShow3 = !this.textShow3
			},

			//首页轮播图
			getBanner(){
				banner().then(res=>{
					console.log(res.data.data);
				})
			},

			//热门排行
			getHotPaihang(){
				hotPaihang().then(res=>{
					console.log(res.data.data,'热门排行榜');
					this.m1leftLsit=res.data.data
				})
			},

			



			//品牌推荐 1:世界500强,2:中华老字号,3:驰名商标
			getTuijian(){
				tuijian({
					type:this.m5tabIndex
				}).then(res=>{
					console.log(res.data.data,'品牌推荐');
					this.m5list=res.data.data
				})
			},
			
			//旅游娱乐 1:旅游,2:品玩
			getLvyou(){
				lvyou({
					type:this.m6tabIndex
				}).then(res=>{
					console.log(res.data.data,'旅游娱乐');
					var dataCon = res.data.data
					var m6picList = dataCon.slice(0, 4);
					var m7list = dataCon.slice(-3);
					this.m6picList= m6picList
					this.m7list = m7list
					console.log(m7list,"旅游排行")
					
				})
			},


			//国家地区馆
			getGuojia(){
				guojia().then(res=>{
					console.log(res.data.data,'国家地区馆');
					var dataCon = res.data.data
					var m8list1 = dataCon.slice(0, 8);
					var m8list2 = dataCon.slice(-5);
					this.m8list1 = m8list1
					this.m8list2 = m8list2
				})
			},




			//跳转链接
			m8arrowLink() {
				this.$router.push({
					path: '/project-detail/project-detail'
				});
			},
			
			// 行业排行-更多列表
			moreBtn() {
				this.$router.push({
					path: '/rankingMore/rankingMore'
				});
			},
				
			//月榜,总榜 切换

			cTabHd(e){
				console.log(e.target.dataset.index)	
				const index = e.target.dataset.index
				this.m1cTabHd = index
				
				this.getHotPinpai()
			},

			


		},
	}
</script>

<style scoped>
	@import "../../assets/css/detail.css";
</style>